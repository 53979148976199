 // Replace with your desired background color


.content-layout {
  grid-area: main;
  padding: 1rem;
}

.sidebar-layout {
  grid-area: sidebar;
  height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

img {
  object-fit: contain;
}

.layout-container {
  background-color: $background-color;
  display: grid;
  grid-template-columns: 1fr; /* Default to a single column on small screens */
  grid-template-areas:
    "header"
    "main"
    "sidebar";
}

/* Media query for screens wider than a specific width (e.g., 768px) */
@media (min-width: 768px) {
  .layout-container {
    gap: 5rem;
    padding-right: 1rem;
    grid-template-columns: 1fr 2fr 2fr; /* Adjust column widths for larger screens */
    grid-template-rows: auto; /* Allow rows to adjust based on content */
    grid-template-areas:
      "sidebar main main"
      "sidebar main main"
      "sidebar main main"
      ;
      
  }
}
