.header {
    padding: 0;
    background: #f7f7f9;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    height: 80px;

    border-radius: 40px;
   
  }
  
  .header-content {
    width: 100%;
    padding-left: 20px;
    border-radius: 40px;
    margin-top: 10px;
   
    background: #F0F0F2;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .header-welcome {
    margin-right: 10px;
    font-family: 'regular';
    color: #00463C;
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
   
    flex: 1;
  }
  
  .header-bell {

    display: flex;
align-items: flex-end;
gap: 20px;
align-items: center;
padding-right: 20px;
  }

  .header-bell-icon {
    font-size: 23px;
    color: #86D694;
  }
  .avatar{
    align-self: center;
    margin-right: 2rem;

  }
  .header-user {
    color: #00463C;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
   

    align-self: center,
   
  }
  



.scroll{
  height: 700px;
  overflow: scroll;
}