.login-container {
    display: flex;
    flex-direction: row;
    height: 100vh; /* Set the height to the viewport height for full-page background */
  }
  
  .login-background {
    flex: 1;
    background-image: url("../svgs/login1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #86D694;
  }
  
  .login-form {
  flex: 1;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  height: 100%;
    background-color: #055146;
  display: flex;
  }
  
  .error {
    /* Add styles for the form container */
     text-align: center;
     font-size: 18px;
     color: red
  }

  .bag-image{
   width: 100px;
   height: 90px;
    right:35%;
    top: 10%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../svgs/bag.svg");
  }


  /* Add CSS for the login form component */
.login-form form {
    width: 60%;
  }
  
  h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }
  
  .lable {
    /* Add CSS styles for labels, if needed */
  }
  
  .form-field {
    width: 100%;
    height: 4rem; /* Adjust the height as needed for responsiveness */
    border: 1px solid #ccc; /* Add border styles as needed */
    border-radius: 25px;
    padding-left: 20px;
    margin-bottom: 20px; /* Adjust the margin as needed */
  }
  
  .lgbtn {
    width: 100%;
    height: 4rem; /* Adjust the height as needed for responsiveness */
    border: 1px solid transparent;
    border-radius: 25px;
    padding: 5px;
    background-color: #86D694;
    border-color: #86D694;
    margin-top: 20px;
    color: #FFF;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }


  /* Add this to your CSS or Sass file */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db; /* Change color as needed */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  
  /* Add any additional styles for the form elements, form container, etc. */
  @media (max-width: 768px) {
    .login-container {
      flex-direction: column; /* Stack items vertically */
    }
    
    .login-background, .login-form {
      flex: auto; /* Make both items take equal space vertically */
    }
  }