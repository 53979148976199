.user-cont {
    grid-area: user;
    background-color: $colors-w;
    border-radius: 1rem;

  }
  .vendor-cont {
    grid-area: vendor;
    background-color: $colors-w;
    border-radius: 1rem;
  }
  .anal-cont{
    grid-area: anal;
    display: flex;
    flex-direction: column;
  }
  .update-cont{
    grid-area: updates;
    display: flex;
    flex-direction: column;
  }
  .revenue-cont{
    grid-area: revenue;
    display: flex;
    flex-direction: column;
    background-color: $colors-lg;
    border-radius: 1rem;
   
    margin-top: 100px;
  }
  .bookings-cont{
    grid-area: bookings;

    background-color: $color-dg;
    border-radius: 1rem;
  }
  .categories-cont{
    grid-area: categories;
    display: flex;
    background-color: $colors-w;
    border-radius: 1rem;
    flex-direction: column;
  }

  .earnings-cont{
    grid-area: earnings;

    display: grid;
    background-color: $colors-w;
    border-radius: 1rem;
   

  }
  
  .e-money{
    color: #FFF;
text-align: center;
font-family: "Source Sans Pro";
font-size: 90px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .dashboard-layout {
    background-color: $background-color;
    display: grid;
    grid-template-columns: 1fr; /* Default to a single column on small screens */
    grid-template-areas:
      "header"
      "main"
      "sidebar";
  }
  .user-details .content{
    color: #00463C;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 100px;
  
  }



  .dash-title{
    color: $color-dg;
    font-family: "Source Sans Pro";
    font-size: 32px;
    font-style: normal;
    text-align: left;
    font-weight: 600;
    margin-bottom: 2rem;
    line-height: normal;
  }

  .user-title{
    color: #00463C;
    font-family: Source Sans Pro;
    font-size:24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .userdetcont{
    display: grid;
    padding: 1rem;
    height: 100%;
    border-radius: 20px;
    padding: 1rem;
    grid-template-rows: 1fr 3fr 1fr;
  }
  .userdetcont img{
    width: 38.247px;
height: 42.958px;
flex-shrink: 0;
margin-right: 1rem;
  }
  .innertext1 {
    color: #86D694;
font-family: "Source Sans Pro";
font-size:4rem;
font-style: normal;
font-weight: 600;
text-align: center;
line-height: normal;
width: 20;
margin-top: 20;
  }
  .innertext2{
    color: #C1E6BA;
font-family: "Source Sans Pro";
font-size: 20px;
font-style: normal;
font-weight: 400;
text-align: center;
line-height: normal;
  }
  .user-details{
    margin-bottom: 20px;
  }
  .small-cont1{
    color: #FFF;
font-family: "Source Sans Pro";
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: center;
  }

  .small-cont2{
    color: #B0EBBD;
    font-family: "Source Sans Pro";
    font-size:45px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .small-cont3{
    color: #FFF;
font-family: Source Sans Pro;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
  }
.update-title{
    color: #00463C;
font-family: "Source Sans Pro";
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: left;


}

.title{
  margin-left: 1rem;
margin-top: 1rem;
margin-bottom: 2rem;
}
  .user-details{
    display: grid;
    border-radius: 3rem;
    height: 4rem;


    justify-content: center;
    align-items: center;
    padding: 0px 10px 0 10px;
    background-color: $colors-w;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
word-wrap: break-word;
}
  
@media (min-width: 768px) {
  .dashboard-layout {
    padding-right: 1rem;
    gap: 2rem;
    grid-template-rows:  0.1fr 0.1fr 1fr ;
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr; /* Adjust column widths for larger screens */
    grid-template-areas:
      "user vendor bookings categories"
      "user vendor earnings earnings"
      "updates updates revenue revenue"  
  }

  /* Adjust the height for each specific card as needed */
  .user-cont,
  .vendor-cont,
  .bookings-cont,
  .categories-cont,
  .earnings-cont,
  .anal-cont,
  .update-cont,
  .revenue-cont {
    min-height: 130px; /* Adjust the height as needed */
  }



.earnings-layout {
    display: grid;
    gap: 2rem;
    grid-template-rows: 0.1fr  1fr;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 1rem;
    grid-template-areas:
      "rev booking-fee charges"
      "transaction transaction trans";
  
   
  }
  
  .rev,
  .booking-fee,
  .charges {
    background-color: $colors-w;
    border-radius: 1rem;
    height: 280px; 
  }
  
  .rev,
  .booking-fee,
  .charges
   {
    display: flex;

    flex-direction: column;
  }
  
  .transaction {
    display: flex;
  }
.rev {
    grid-area: rev;
    background-color: $colors-w;
    border-radius: 1rem;
   
  
  }
  .booking-fee {
    grid-area: booking-fee;
    min-height: 150px;
    border-radius: 1rem;
  }
  .charges{
    grid-area: charges;
    min-height: 150px
  }
  .transaction{
    grid-area: transaction;
    display: flex;
      flex-direction: column;
  }
  .trans{
    grid-area: trans;
  
  }
}