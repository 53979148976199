


html {
  scroll-behavior: smooth;
  background: #86D694;
  overflow-x: hidden;

}

body {
  overflow-x: hidden;
  background-color: #86D694;


}

.menu-list {
  padding: 0px !important;
}

.appBg {
  background: #86D694;
}

.appSecondaryBg {
  background-color: #B0EBBD;
}

.appTertiary {
  background-color: #8DDE9B;
}

.appTextBg {
  background-color: #055146;
}

.maintext {
  font-family: serif;
  font-weight: bolder !important;
  font-style: italic;
  transform: rotate(-2deg);
  color: #055146;
}

.pamp-text {
  font-family: serif;
  font-weight: bolder !important;
  font-style: italic;
  color: #055146;
  font-size: 6rem;
}

.container {
  /* background-color: red; */
}

.centerit {
  display: flex;
  align-items: center;
  justify-content: center;

}

.landing-bubble {
  transform: translate(-120px, 0px);

}

.booking-wrapper {
  width: fit-content;
  /* margin-left: auto; */
  /* transform: translate(-111px, 100px); */
}

.w-full {
  width: 100%;
}

.service-text {
  line-height: 25px !important;
}

.cta {
  background-color: white;
  background: white;
  border-radius: 4rem;
  padding: 1rem 4rem;
  font-size: 1.2rem;
  outline: none;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 25px;
  padding-right: 10px;
  position: relative;
  z-index: 6;
}

.chevron-right {
  height: 25px;
}


.cta {
  text-decoration: none;
  color: #86D694;

}

.cta:hover {
  color: #86D694;
}

.ml-20 {
  margin-left: 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  margin: auto;

  align-items: flex-start;
  padding: 50px 10px;
  padding-top: 40px !important;

}


.service-sm {
  display: none;
}


.waitlist-page {
  background-color: #86D694;
  height: 900px;
  overflow-y: hidden;
}

.choose-waitlist-text {
  color: #DAECD2;
  /* secondary */
  font-size: 2rem;
  margin-bottom: 50px;
  font-weight: bold;
  text-align: center
}

.wait-flip-flop {
  transform: scale(1.5);
  position: absolute;
  top: 150px;
  left: 150px;
}



.wait-svg {
  margin: auto;
  top: -200px;
  position: relative;
  display: block;
  /* transform: scale(0.8); */
}


.wait-arrow {
  position: relative;
  top: -750px;
  left: 80px;
  transform: scale(0.8);
}


.waitlist-option {
  margin: auto;
  margin-top: 20px;
  position: relative;
  padding: 20px;
}




.waitlist-link-one {
  /* background-color: red; */
  width: 445px;
  height: 200px;
  display: block;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 150px;
  transform: translateX(-50%);
  left: 50%;
  /* width: 90%; */
}



.waitlist-link-two {
  /* background-color: red; */
  width: 445px;
  height: 200px;
  display: block;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 400px;
  transform: translateX(-50%);
  left: 50%;
  /* width: 90%; */
}


.congrats-bg {
  margin: auto;
  display: block;
}

.congrats-wrapper {
  position: relative;
  margin: auto;
  width: fit-content;
}

.congrats-page {
  overflow-x: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.congrats-flip-flop {
  position: absolute;
  top: 200px;
  left: -100px;
  transform: scale(1.2);
}



.input {

  background-color: white;
  border: 1px solid #86D694;
  outline: none;
  color: #111;
  display: block;
  width: 100%;
  padding: 0.625rem 1rem;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 1rem;
  border-radius: 0.5rem;


}


.input:focus {
  border-color: #86D694;

}

.client-submit-button {
  color: white;
  background-color: #86D694;
  border-radius: 999px;
  font-weight: 500;
  width: 100%;
  padding: 0.75rem 2.5rem;
  margin: auto;
  outline: none;
  border: 1px solid #86D694;
  display: block;
  width: fit-content;
  margin-top: 40px;
}



.client-form {
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 1rem;
  padding: 1.75rem;
  height: fit-content;
}

.form-header {

  color: #86D694;
  font-size: 24px;
  text-align: center;
  padding-bottom: 1.25rem
}



.client-bottom-wrapper {
  /* position: absolute; */
  bottom: 40px;
  display: flex;
  justify-content: center;
  left: 50%;
}

.mx-auto {
  margin: auto;
}



.client-waitlist-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 180px;
  max-width: 1000px;
  margin-top: 20px;
  position: relative;
  /* padding-left: 1.25rem;
  padding-right: 1.25rem; */
  padding-bottom: 2.5rem
    /* grid client grid-cols-[1fr_1fr] gap-[180px] max-w-[1000px] md: mx-auto mt-[20px] relative px-5 */
}

.relative {
  position: relative;
}


.partner-arrow {
  display: block;

  margin-top: -150px;
}


.become-partner {
  display: block;

}

.partner-waitlist {
  position: relative;
  margin-top: -130px;
  z-index: 3;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: 1000px !important;
  /* max-w-[1000px]  container md:mx-auto mt-[-130px] z-index-[3] relative px-5  */
}

.text-appText {
  color: #055146;
}

.partner-text-wrapper {
  color: #055146;
  padding-left: 1.75rem
    /* 28px */
  ;
  padding-right: 1.75rem
    /* 28px */
  ;
  font-size: 1.3rem;
}


.px-7 {
  padding-left: 1.75rem
    /* 28px */
  ;
  padding-right: 1.75rem
    /* 28px */
  ;
}




.mb-4 {
  margin-bottom: 1rem;
}



.fn-ln {
  width: 100%;
  margin-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem
    /* w-full md:w-1/2 px-3 mb-3 md:mb-0 */
}


.fn-ln-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: 0.5rem;
}


.partner-form {
  width: 80%;
  margin: auto;
  position: relative;
  background-color: white;
  margin-top: 2.5rem
    /* 40px */
  ;
  border-radius: 1rem
    /* 16px */
  ;
  padding: 1.75rem
    /* 28px */
  ;

}

.partner-button {
  color: white;
  width: fit-content;
  background-color: #86D694;
  border-radius: 999px;
  outline: none;
  border: 1px solid #86D694;
  padding: 0.75rem 2.5rem;
  font-family: 'Source Sans Pro', sans-serif;

}


.partner-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}



.ghana-svg {
  position: absolute;
  top: 1rem
    /* 16px */
  ;
  left: 0.625rem
    /* 10px */
  ;
}

.pl-10 {
  padding-left: 2.5rem
    /* 40px */
  ;
}


.partner-wrapper {
  max-width: 1000px;
  margin: auto;
}



.mt-30 {
  margin-top: 7.25rem;
}

.form-arrow {
  display: block;
  margin-top: -100px;
  height: 560px;

}





.partner-footer {
  margin-top: -405px;
  z-index: 4;
}




.menu-main-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #055146;
  position: absolute;
  z-index: 30;
  justify-content: center;
  align-items: center;
  display: flex;
}





.menu-wrapper {
  width: 500px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: auto;
  z-index: 40;
  margin: auto;

}



.inactive-menu-link {
  font-size: 3rem;
  font-style: italic;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  border-bottom: 1px solid #087263;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  list-style: none;

}



.inactive-menu-link a:hover {
  color: #087263 !important;



}



.text-secondaryText a {
  color: #DAECD2 !important;
  text-decoration: none;
}


.text-navText a {
  color: #087263;
  text-decoration: none;

}


.active-menu-link {
  font-size: 3rem;
  font-style: italic;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  border-bottom: 1px solid #087263;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  list-style: none;

}




.active-menu-link a:hover {
  color: #DAECD2;
}



.menu-tree {
  position: absolute;
  bottom: -200px;
  right: 0;

}



.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all .5s ease-in-out;
  transform: scale(0.5);
  z-index: 40;
  /* border: 3px solid #fff; */
}

.menu-btn__burger {
  width: 50px;
  height: 6px;
  background: #DAECD2;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 6px;
  background: #DAECD2;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-16px);
}

.menu-btn__burger::after {
  transform: translateY(16px);
}

/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}


.phone-tree {
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}


.landing-bg {
  background-image: url('./assets/images/landing_bg.png');
  background-color: #86D694;
  /* background-position: center; */
  background-size: cover;
  background-repeat: no-repeat;
}

.cloud-one-wrapper {
  display: flex;
  justify-content: space-around;
}

.landing-birds {

  position: absolute;
  right: 80px;
  top: -20px;
}


.booking-dot {
  display: block;
  position: absolute;
  left: 30px;
  top: 250px;

}


.landing-phone-dot {
  display: block;
  position: absolute;
  right: 30px;
  top: 250px;
}




.landing-phone {
  position: relative;
  z-index: 1;
  scale: 1.2;


}


.about-pamp-cloud-one {
  position: absolute;
  left: 50px;
  top: -50px;
}

.about-pamp-dot {
  position: absolute;
  right: 650px;
  top: -50px;
}


.about-pamp-cloud-two {
  position: absolute;
  right: 50px;
  top: -50px;
}


.about-pamp-cloud-girls {
  position: absolute;
  left: -200px;
  top: 40px;
  transform: scale(1.5);
}

.about-pamp-birds {
  position: absolute;
  left: -200px;
  top: 100px;
}

.everyone-tree-right {
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}


.everyone-tree-left {
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}

.everyone-dot-one {
  position: absolute;
  right: 550px;
  top: 50px;
}

.everyone-dot-two {
  position: absolute;
  right: 350px;
  bottom: 100px;
}

.everyone-cloud {
  position: absolute;
  top: 20px;
  left: 80px
}

.everyone-bird {
  position: absolute;
  right: 100px;
}

.business-brid-one {

  position: absolute;
  left: -9px;
  top: 80px;
}

.business-dot-one {
  position: absolute;
  left: -9px;
  top: 180px;
}

.business-dot-two {
  position: absolute;
  left: 350px;
  top: -100px;
}

.business-cloud-one {
  position: absolute;
  right: 200px;
  transform: scale(0.8);
  /* background-color: red; */
}

.business-dot-three {
  position: absolute;
  right: -9px;
  top: 250px;
}


.business-brid-two {
  position: absolute;
  right: -9px;
  top: 10px;
}

.business-cloud-two {
  position: absolute;
  bottom: 0px;
  left: 200px;
}


.overflow-hidden {
  overflow: hidden;
}



.contact-us-wrapper {
  max-width: 1000px;
  margin: auto;
  position: relative;
  margin-top: 80px;
  padding-left: 1.25rem;
  padding-right: 1.25rem
}


.contact-us-bird-one {
  position: absolute;
  right: -30px;
  top: -100px;
}


.contact-us-cloud-one {
  position: absolute;
  right: 150px;
  top: -120px;
}


.contact-us-cloud-two {
  position: absolute;
  left: 100px;
  top: -100px;
}


.contact-us-dot-one {
  position: absolute;
  left: 500px;
  top: -180px;
}

.contact-us-inner-wrapper {
  max-width: 500px;
  margin: auto;
}


.talk-us-text {
  color: #DAECD2;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  font-family: 'Source Sans Pro', sans-serif;

}


.email-wrapper {
  color: #DAECD2;
  font-size: 1.9rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.mr-2 {
  margin-right: 0.5rem
    /* 8px */
  ;
}

.connect-text {
  color: #DAECD2 !important;
  font-size: 1.8rem !important;
  font-weight: bold;
  text-align: center;
  margin-top: 2.5rem;
  text-align: center;
  margin: 0px !important;
  margin-top: -10px !important;
  font-family: 'Source Sans Pro', sans-serif;

}

.contact-us-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
}



.contact-us-link {
  transition-duration: 300ms;
}

.contact-us-link:hover {
  transform: translateY(-0.25rem) scaleX(1.1) scaleY(1.1);
  transition-duration: 300ms;
}



.contact-us-page {
  height: 100vh;
  overflow: hidden;
}







.about-pamp-main {
  /* padding-top: 100px; */



}






















































.navbar-wrapper {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    left: 100%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}






















/* .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
} */


















@media (max-width: 380px) {
  #scanmobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 140px;
    gap: 20px;
    border-radius: 10px;
  }
  #scanmobile img:first-child{
    height: 25px;
  }
  #scanmobile img:last-child{
    height: 38px;
  }

  #appsvg{
    position: relative;
    align-items: center;
    width: 100%;
    padding-left: 40px;
  }
  #dcont{
    height: fit-content;
    border-radius: 20px;
  }
  #dcont img{
    height: fit-content;
  }

}

@media (max-width: 500px) {
  // #scanmobile img:first-child{
  //   height: 22px;
  // }
  // #scanmobile{
  //   height: 60px;
  // padding: 10px;
  
  // }
}





@media (min-width: 768px) {
  #desk,#desk2 {
    display: block ;
  }
#mobile,#mobile2 {
    display: none;  
  }
}




















.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all .5s ease-in-out;
  transform: scale(0.5);
  /* border: 3px solid #fff; */
}

.menu-btn__burger {
  width: 50px;
  height: 6px;
  background: #DAECD2;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 6px;
  background: #DAECD2;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-16px);
}

.menu-btn__burger::after {
  transform: translateY(16px);
}

/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}


.girls-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40% !important;
}

#about-pamp {
  position: relative;
}

.about-pamp-text {
  font-size: 1.6rem;
  color: #055146;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 0;

}

.about-pamp-texts {
  width: 600px;
  margin-left: auto;

}

.about-boat {
  position: absolute;
  left: -70px;
  top: 200px;
}

.about-temp-texts-wrapper {
  position: relative;
}

.about-pamp-text-main {
  width: 600px;
  margin-left: auto;
}



.maintext2 {
  font-family: serif;
  font-weight: bolder !important;
  color: #055146;
}

.services-title {
  color: #055146;
  font-weight: bold !important;
  font-family: 'Bodoni Moda', serif;
  font-size: 3rem;
  text-align: center;
  margin: auto;
  letter-spacing: 0.1em;
  padding-top: 50px;
  padding-bottom: 20px;
}


.service-flex {
  display: flex;
  justify-content: center;
  /* grid-template-columns: repeat(3, auto); */
  margin-bottom: 24px;
  gap: 8px;
}


.service-flex-sm {
  display: flex;
  justify-content: center;
  /* grid-template-columns: repeat(3, auto); */
  margin-bottom: 24px;
  gap: 8px;
}

.service-item {
  /* border-2 border-appBg py-2 p-[5px] text-center  mr-2 text-[1.3rem]    text-appText mb-5 rounded-full cursor-pointer */
  border: 2px solid #86D694;
  padding: 8px 70px;
  text-align: center;
  font-size: 1.3rem;
  color: #055146;
  cursor: pointer;
  display: inline-block;
  border-radius: 999px;
  margin-right: 20px;
}

.service-item-sm {
  /* border-2 border-appBg py-2 p-[5px] text-center  mr-2 text-[1.3rem]    text-appText mb-5 rounded-full cursor-pointer */
  border: 2px solid #86D694;
  padding: 8px 10px;
  text-align: center;
  /* font-size: 1.3rem; */
  color: #055146;
  cursor: pointer;
  display: inline-block;
  border-radius: 999px;
  margin-right: 10px;
  font-size: 13px;
}

.service-item-sm-nb {
  /* border-2 border-appBg py-2 p-[5px] text-center  mr-2 text-[1.3rem]    text-appText mb-5 rounded-full cursor-pointer */
  padding: 8px 10px;
  text-align: center;
  /* font-size: 1.3rem; */
  color: #055146;
  cursor: pointer;
  display: inline-block;
  border-radius: 999px;
  margin-right: 10px;
  font-size: 13px;
}

.service-item-nb {
  padding: 8px 70px;
  text-align: center;
  font-size: 1.3rem;
  color: #055146;
  cursor: pointer;
  display: inline-block;
  border-radius: 999px;
}

.hidden {
  display: none;
}

.py-10 {
  padding: 50px 0;
}

.pamp-class {
  margin-top: -200px;
  align-self: center;
}


.business-boat {
  position: absolute;
  bottom: 100px;
  right: 50px;
}


.business-phone-wrapper {
  position: relative;
}

.business-phone-wrapper img {
  z-index: 2;
  position: relative;
}


.waitlist-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  position: relative;
  padding: 100px 0;
}


.join-waitlist-button {
  display: flex;
  background-color: white;
  text-align: center;
  font-size: 24px;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 15px 40px;
  padding-right: 20px;
  align-items: center;
  text-decoration: none;
  color: #8DDE9B;
  border-radius: 999px;
  font-style: italic;
  justify-content: center;
}


.join-waitlist-button:hover {
  color: #8DDE9B;
}

.ml-10 {
  margin-left: 40px;
}

.wait-bg {
  background-image: url('./assets/images/wait.png');
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}


.footer {
  display: grid;
  grid-template-columns: 30% 70%;
  margin: auto;
  padding: 40px;
  font-family: 'Source Sans Pro', sans-serif;


}



.footer-logo {
  transform: scale(0.8);
}


.connect-text-footer {
  color: #B0EBBD;
  margin-top: 20px;
  font-size: 22px;
}


.sitemap {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.footer-link {
  transition-duration: 300ms;

}

.footer-link:hover {
  transform: translateY(-0.25rem) scaleX(1.1) scaleY(1.1);
  transition-duration: 300ms;
}

.mr-5 {
  margin-right: 1.25rem;
}

.sitelinks {
  display: flex;
  justify-content: space-evenly;
}

.sitelink-title {
  color: #DAECD2;
  font-size: 25px;

}

.fw-600 {
  font-weight: 600;
}

.sitelink-list {
  margin-top: 20px;
  padding-left: 20px;
}


.sitelink-list-item {
  color: white;
  font-size: 18px;
  margin-bottom: 12px;
  list-style-image: url('./assets/svgs/list_style.svg');
  text-decoration: none;
}




.sitelink-list-item a {

  text-decoration: none;
  color: white;
}

.sitelink-list-item a:hover {
  color: #B0EBBD;
}

.pl-20 {
  padding-left: 20px;
}







@media only screen and (max-width: 499px) {

  .business-pamp {
    transform: translateX(-20px);
  }

  .wait-svg {
    margin-top: -100px;
  }

  .waitlist-link-one {
    top: 100px;
  }

  .waitlist-link-two {
    top: 330px;

  }

}



@media only screen and (max-width: 600px) {

  /* .pamp-svg {
    height: 450px;
  } */

  .congrats-bg {
    width: 80%;
  }

  .become-partner {
    width: 350px;
  }

}



@media only screen and (max-width: 1024px) {
  .split {
    flex-direction: column;
  }

  .landing-bubble {
    display: none;
  }

  .landing-phone {
    transform: rotate(-10deg) translateX(20px) scale(1.5);
    width: 100%;
    margin-top: 180px;
    margin-bottom: 50px;
    z-index: 2;
    left: 13px
  }

  .business-phone {
    transform: translateX(20px) scale(1.3);
    width: 100%;
    margin-top: 150px;
    margin-bottom: 50px;
  }

  .about-pamp-text-main,
  .about-pamp-texts {
    width: 100%;
  }

  .about-pamp-text {
    font-size: 22px;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 24px;

  }

  .about-pamp-text-main {
    margin: auto;
    text-align: center;
  }

  .break-text {
    display: block;
  }

  .about-boat {
    display: none;
  }

  .girls-image {
    /* background-color: red; */
    width: 80% !important;
    position: unset;
  }


  .about-pamp-main {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    /* margin-top: 200px; */

  }

  .everyone-image {
    content: url(./assets/images/mobile_people.png);
  }

  .pamp-class {
    /* margin-top: -50px; */
    margin: auto;
    transform: translateX(-20px) scale(0.9);
    height: 450px;
  }

  .hide-mobile {
    display: none;
  }

  .footer {
    display: block;
  }

  .waitlist-wrapper {
    padding: 100px 0;

  }


  .bsm-text {
    text-align: center;
    font-size: 3.8rem !important;
  }

  .booking-wrapper {
    margin: auto;
  }

  .business-pamp {
    /* transform: scale(0.8); */
    height: 450px;
  }



  .service-md {
    display: none;
  }


  .service-sm {
    display: block;
    padding-bottom: 20px;
  }



  .break-choose-text {
    display: block;
    margin-top: -15px;
  }




  .client-bottom {
    /* bottom: 0; */
    /* position: unset; */
    margin-top: 100px;
    position: relative;
    max-width: 200px;
  }



  .client-svg {
    display: block;
    transform: scale(0.8);
    margin-top: -100px;
  }



  .client-waitlist-wrapper {
    display: block
  }

  .partner-text-bg {
    margin-top: 0px;
  }




  .mobile-bubble {
    display: block;
    position: relative;
    top: -50px;
    height: 400px;
    margin: auto;
    transform: translate(-20px, -20px) scale(1.1);
    width: 380px;
    left: 0;

  }


  .partner-form {
    width: 100%;
    margin-bottom: 100px;
  }




  .menu-wrapper {
    width: 100%;

  }


  .about-pamp-cloud-two {
    display: block;
    margin: auto;

  }


  .services-title {
    font-size: 2rem;
  }

  /* 
  .wait-svg {
    height: 360px;
    margin: auto;
    transform: scale(1);
    position: relative;
    left: -100px;
    top: -40px;
    background: red;

  } */
  .wait-svg-wrapper {
    margin: auto;
    display: flex;
    justify-content: center;
  }



  .client-svg-wrapper,
  .become-partner-wrapper {
    margin: auto;
    display: flex;
  }



  .client-svg {
    display: block;
    transform: scale(0.8);
  }



  .become-partner {
    display: block;
    transform: scale(0.9);
  }


  .about-pamp-text {
    margin-bottom: -1.5rem !important;
    padding-top: 0;
    padding-bottom: 0;
  }


  .business-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }


  .waitlist-page {
    overflow-x: hidden;
  }




}

@media only screen and (max-width: 600px) {

  .connect-text {

    font-size: 1.4rem !important;
  }

  .services-title {
    padding-top: 25px;
  }

  .business-phone-wrapper img {
    margin-top: 0px;
  }

  .business-pamp {
    position: relative;
    left: 20px;
  }

  .break-text {
    display: block;
  }

}


@media only screen and (max-width: 321px) {
  .mobile-bubble {
    scale: 0.8;
    left: -35px;
    top: -105px;
  }

  .pamp-class {
    height: 300px;
    left: -44px;
    position: relative;
  }

  .everyone-bird {
    display: none;
  }


  .business-pamp {
    height: 350px;
    left: -36px;
    position: relative;
  }

  .join-waitlist-button {
    font-size: 20px;
    width: fit-content;
  }

  .cta {
    font-size: 20px;
    padding: 0.8rem 3rem;
    padding-right: 10px;
  

  }

  .booking-wrapper {
    scale: 0.9;
  }

  .bsm-text {
    font-size: 20px;
    font-size: 3.3rem !important;
  }

  .easy-text {
    font-size: 3rem !important;
  }

  .service-sm {
    display: none;
  }

  .servives-xs {
    display: flex;
  }

  .service-item-sm {
    margin-bottom: 10px;
  }

  .services-title {
    font-size: 1.5rem;
  }

  .connect-text {
    font-size: 1.5rem !important;
  }

  .choose-waitlist-text {
    font-size: 1.8rem !important;

  }


}