

.admin-sidebar {
 background-color: $color-dg;
 overflow: auto;
 align-self: center;
 margin-top: 2rem;

 height: 100vh;
border-top-left-radius: 25px;
border-top-right-radius: 20px;
 padding-bottom: 5rem;

 margin-left: 1rem;
}




.ant-menu-dark .ant-menu-item-selected{
    background-color: #86D694;
    color: #00463C !important;
   
  }
  

  .ant-menu-inline >.ant-menu-item, 
   .ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title{
    margin-top: 15px;
    margin-bottom: 15px;
   
   
  }
  
  
  .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    border-radius: 10px;
    background-color: #002B25;
    margin-left: 20px;
    
    margin-right: 20px;
   
   
  }
  
  .ant-layout .ant-layout-sider-trigger{
    display: none;
  }
  
  .ant-menu .ant-menu-item .ant-menu-item-icon +span, .ant-menu .ant-menu-submenu-title .ant-menu-item-icon +span, .ant-menu .ant-menu-item .anticon +span, .ant-menu .ant-menu-submenu-title .anticon +span{
    margin-inline-start: 35px;
  }
  
  
  .ant-menu-inline.ant-menu-root .ant-menu-item >.ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title >.ant-menu-title-content{
    color: #DAECD2;
    font-size: larger;
  
  }
  
  .A2 {
    background-color: #00463C;
  }
  
  
  
  
    .moduleSection {
      background-color: #fff;
      border: 1px solid #ccc;
      border-collapse: collapse;
      border-radius: 6px;
      
  }
  