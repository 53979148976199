
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}
body {

  font-family: 'Bodoni Moda', serif;
  font-family: 'Source Sans Pro', sans-serif;
}


:root {
  --color-dg: #00463C;
  --color-lg: #86D694;
  --color-w: #FFFFFF;
  --color-red: #CD3D49;
}

ul {
  list-style-type: disc; /* You can use 'circle' or 'square' as well */
}

li {
  margin-bottom: 0.5rem; /* Adjust spacing as needed */
}

.ant-drawer-body{
  background-color: #f7f7f9;
}