.search,
.search input {
  border-radius: 40px;
  background-color: #F0F0F2;

}

.search {
  margin-top: -10px;
  margin-left: 20px;

  height: 40px;
  border-radius: 40px;
  border-color: #F0F0F2;
  width: 250px;

}

.query {
  position: relative;
  left: 58%;
  margin-top: 20px;
  width: 40%;
  top: 3rem;

}

.status {
  border-radius: 24px;
  background: #00463C;
  width: 100px;
  height: 30px;
  margin: auto;
  color: #FFF;
  justify-content: center;
  padding-top: 5px;
}

.table-body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* Adjust the minmax value as needed */
  gap: 10px;
  background-color: #ffff;
  border-radius: 20px;
  padding: 10px;
}

.table-head-container {

  display: grid;
  grid-template-columns: repeat(minmax(100px, 1fr));
  /* Adjust the minmax value as needed */
  gap: 10px;

  overflow: auto;

  /* Set a fixed width for the container */
}

.table-head {
  width: 100%;
  display: flex;

  background-color: #ffff;
  border-radius: 20px;
  padding: 10px;

  /// grid-template-columns: repeat(minmax(150px, 1fr)); /* Adjust the minmax value as needed */
  // Prevent text from wrapping */
}

img,
.cursor {
  cursor: pointer;
}


.flex-item {
  flex: 1;
  width: 120px;
  text-align: center;
  /* Show ellipsis for long text */
  color: #00463C;
  justify-self: center;
  align-items: center;
  /* Vertically center items */
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reqbody {

  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 10px;
  background-color: $colors-w;
}

.price {
  color: #86D694;
  font-weight: 800 !important;

}

.height {
  height: 100px !important;
}

.no {
  border-radius: 24px;
  background: #86D694;
  width: 60px;
  height: 30px;
  margin: auto;
  color: #FFF;
  justify-content: center;
  padding-top: 5px;
}

.tred {
  align-items: center;
  /* Vertically center items */
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #DAECD2;
  width: 100%;
  height: 60px;

}

.tred3 {
  align-items: center;
  /* Vertically center items */
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #DAECD2;
  width: 100%;
  height: 60px;
}

.tred2 {
  align-items: center;
  /* Vertically center items */
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #DAECD2;
  width: 100%;
  height: 60px;
}

.action {
  border-radius: 24px;
  cursor: pointer;
  width: 60px;
  height: 30px;
  padding: 10px;
  flex-shrink: 0;
  align-items: center;
  /* Center text horizontally */
  justify-content: center;
  /* Center text vertically */
  text-align: center;
  margin-left: 1rem;
  font-size: 11px;

}

.action:hover {
  background-color: #46a049;
}

.action-img {}

.decline {
  background-color: $color-red;
  color: $colors-w;
}

.icont {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-left: 1rem;
}

.flex-item div:nth-child(2) {

  text-align: left;
}

.approved {
  border-radius: 24px;
  border: 1px solid #DAEC;
  color: #86D694;
}

.unapproved {
  color: #FFF;
  background: #86D694;
}

.edit {
  align-items: center;
  /* Vertically center items */
  justify-content: center;

}

.input {
  height: 55px;
  flex-shrink: 0;
  border: 0;
  color: #00463C;
  padding-left: 30px;
  border-radius: 39px;
  background: #FFF;

}

.input-long {
  width: 30;
}


.title-med {

  color: #00463C;
  font-family: 'Source Sans Pro';
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 2rem;
  margin-bottom: 10px;
}

.ant-checkbox .ant-checkbox-inner {
  width: 40px;
  height: 40px;

}

.custom-checkbox {
  display: flex;
  align-items: center;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox label {
  position: relative;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 10px;
  /* You can adjust the border-radius */
  border: 2px solid #86D694;
  /* Green border color */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox .check-mark {
  color: #fff;
  /* White check sign color */
  font-weight: bold;
  /* Adjust the font weight for a thicker check sign */
}

.custom-checkbox.checked label {
  background: #86D694;
  /* Green background when checked */
  border-color: #86D694;
  /* Border color when checked */
}

.checkbox-label {
  margin-left: 10px;
  font-size: 20px;
  color: '#00463C'
}

.custom-pagination-button {
  background-color: #86D694;
  ;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  line-height: 1;
  /* Set line height to match the page number */
}

.custom-pagination-button:hover {
  background-color: #45a049;
}


/* Custom styles for pagination container */
.ant-pagination {
  display: flex;
  justify-content: flex-end;
}



.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #DAECD2;
  border-color: #DAECD2;
  border-radius: 20px;
}

.logo-container {
  transition: all 0.3s ease-in-out;
}

.ant-drawer-title {
  color: #86d694 !important;

  font-family: 'Source Sans Pro' !important;
}

.password-input-container {
  position: relative;
}

.toggle-password-icon {
  position: absolute;
  top: 35%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}




.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #86d694;
}

.ant-picker .ant-picker-input>input {
  padding-top: 5px;
}

.ant-picker-now-btn{
  color: #86D694;
  text-decoration: none;
}

.ant-btn-primary{
background-color:#86D694;
color: white;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border: #86D694;
  color: #00463C;
}

.ant-picker-header-view{
  color: #86D694;
}
